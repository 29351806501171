<template>
	<div>
		<!--banner top part-->
		<section class="intregrationBanner marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h1>Join SEO Suite’s growing network of integration partners!</h1>
					</div>
				</div>
			</div>
		</section>
		<section class="subintregration">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h2>Available integrations</h2>
						<p>Some dummy text that can easily be removed if neccessary</p>
					</div>
				</div>
			</div>
		</section>

		<!--end-->
		<!--main content-->
		<section class="intregration_services">
			<div class="container">
				<div class="row">
					<!--box-->
					<div class="col-md-4">
						<div class="intregrationBox">
							<img src="@/assets/images/icon-01.svg" alt="" />
							<h3>API</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
								purus commodo vel nunc.
							</p>
						</div>
					</div>
					<!--end-->
					<!--box-->
					<div class="col-md-4">
						<div class="intregrationBox">
							<img src="@/assets/images/icon-02.svg" alt="" />
							<h3>Shopify</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
								purus commodo vel nunc.
							</p>
						</div>
					</div>
					<!--end-->
					<!--box-->
					<div class="col-md-4">
						<div class="intregrationBox">
							<img src="@/assets/images/icon-03.svg" alt="" />
							<h3>WordPress</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
								purus commodo vel nunc.
							</p>
						</div>
					</div>
					<!--end-->
					<!--box-->
					<div class="col-md-4">
						<div class="intregrationBox">
							<img src="@/assets/images/icon-04.svg" alt="" />
							<h3>Google Analytics</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
								purus commodo vel nunc.
							</p>
						</div>
					</div>
					<!--end-->
					<!--box-->
					<div class="col-md-4">
						<div class="intregrationBox">
							<img src="@/assets/images/icon-05.svg" alt="" />
							<h3>Google Search Console</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
								purus commodo vel nunc.
							</p>
						</div>
					</div>
					<!--end-->
					<!--box-->
					<div class="col-md-4">
						<div class="intregrationBox">
							<img src="@/assets/images/icon-06.svg" alt="" />
							<h3>Google Calendar</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
								purus commodo vel nunc.
							</p>
						</div>
					</div>
					<!--end-->
				</div>
			</div>
		</section>
		<!--end-->

		<!--call action intregration -->
		<section class="intre_Callaction">
			<div class="container">
				<h3>
					Don’t hesitate. <br />
					Integrate with SEO Suite now!
				</h3>
				<a
					:href="`https://my.seosuite.io/${$i18n.locale}/signup`"
					target="_blank"
					class="callbtn"
					>Start free trial</a
				>
			</div>
		</section>
		<!--end-->
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";
	export default {
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
