import { render, staticRenderFns } from "./Integrations.vue?vue&type=template&id=5270b95c&scoped=true&"
import script from "./Integrations.vue?vue&type=script&lang=js&"
export * from "./Integrations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5270b95c",
  null
  
)

export default component.exports